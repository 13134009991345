import { ClaimStatus } from '@paid-ui/enums/claim';
import { ContractRequirementType, ContractState } from '@paid-ui/enums/contract';
import { PartyLinkingStatus } from '@paid-ui/enums/party';
import { userManager } from '@paid-ui/models/user';
import dayjs from 'dayjs';

import { contractManager } from '../model';

export const savePermissions = () => {
  const { impersonation, disableContractActions } = userManager;
  const {
    contractState,
    hasAllSigned,
    commencementDate,
    actualCompletionDate,
    payments,
    currentParty,
    lastReviewParty,
    isPayee,
    isInvitee,
    inviteeParty,
    isInviter,
    requirements,
    statuses: {
      isPrimeContract,
      isOffPlatformContract,
      isContractExecuted,
      isDraftContract,
      isRBNContract,
      isSuperContract,
    },
  } = contractManager;

  const isContractSubmitted = contractState === ContractState.SUBMITTED && !isDraftContract;

  const isCommencementDateBeforeToday =
    !!commencementDate && dayjs(commencementDate).isBefore(dayjs().startOf('day'));

  const hasPaymentUnclaimed = payments?.some((payment) => !payment.claim);

  const hasPaymentUnclaimedOrRejected = payments.some(
    (payment) => !payment.claim || payment.claim?.state === ClaimStatus.REJECTED,
  );

  const canAbandonContract =
    !disableContractActions &&
    isInviter &&
    !isPrimeContract &&
    !isDraftContract &&
    [ContractState.SUBMITTED, ContractState.RESUBMITTED, ContractState.REJECTED].includes(
      contractState,
    );

  const canCreateClaim =
    !disableContractActions &&
    isPayee &&
    !isDraftContract &&
    isContractExecuted &&
    hasAllSigned &&
    hasPaymentUnclaimed;

  const canClaimRetention = false;
  const disableVariation = requirements.some(
    (requirement) => requirement.type === ContractRequirementType.DISABLED_VARIATION,
  );

  const canCreateVariation =
    !disableContractActions &&
    !disableVariation &&
    ((isPrimeContract && isPayee) || !isPrimeContract) &&
    !isDraftContract &&
    isContractExecuted &&
    hasAllSigned &&
    hasPaymentUnclaimedOrRejected;

  const canResendInvitation = isPrimeContract
    ? !disableContractActions &&
      isContractSubmitted &&
      isInviter &&
      inviteeParty?.linkingStatus === PartyLinkingStatus.UNLINKED
    : !disableContractActions &&
      isContractSubmitted &&
      isInviter &&
      inviteeParty?.linkingStatus === PartyLinkingStatus.LINKED;

  const canReviewOnPlatformContract =
    !disableContractActions && !isOffPlatformContract && !!currentParty && isInvitee;

  const canReviewOffPlatformContract =
    !disableContractActions &&
    isOffPlatformContract &&
    !!currentParty &&
    currentParty.id !== lastReviewParty?.id;

  const canReviewContract =
    !disableContractActions &&
    (canReviewOnPlatformContract || canReviewOffPlatformContract) &&
    [ContractState.SUBMITTED, ContractState.RESUBMITTED].includes(contractState);

  const canSendCommencementNotice =
    !disableContractActions &&
    !isDraftContract &&
    isPayee &&
    isContractExecuted &&
    !commencementDate;

  const canSendCompletionNotice =
    !disableContractActions &&
    !isDraftContract &&
    isPayee &&
    isContractExecuted &&
    !actualCompletionDate &&
    isCommencementDateBeforeToday;

  const canSignContract =
    !disableContractActions &&
    isPrimeContract &&
    !isOffPlatformContract &&
    contractState === ContractState.ACCEPTED &&
    currentParty?.linkingStatus !== PartyLinkingStatus.SIGNED;

  const canViewContract = !isDraftContract;
  const canEditDraftContract = !disableContractActions && isDraftContract;
  const canDeleteDraftContract = !disableContractActions && isDraftContract;

  const canRevokeRBNContract =
    !disableContractActions &&
    impersonation &&
    isRBNContract &&
    contractState === ContractState.ACCEPTED &&
    !hasAllSigned;

  const canRevokeSuperContract =
    !disableContractActions &&
    isSuperContract &&
    contractState === ContractState.ACCEPTED &&
    !hasAllSigned;

  const canRevokeContract =
    !disableContractActions && (canRevokeRBNContract || canRevokeSuperContract);

  contractManager.permissions = {
    canAbandonContract,
    canCreateClaim,
    canCreateVariation,
    canResendInvitation,
    canReviewContract,
    canSendCommencementNotice,
    canSendCompletionNotice,
    canSignContract,
    canViewContract,
    canClaimRetention,
    canRevokeContract,
    canEditDraftContract,
    canDeleteDraftContract,
  };
};
