import { FlyoutType, openApplyAccountDialog, openFlyout } from '@paid-ui/blocks/global-flyout';
import { UnstyledButton } from '@paid-ui/components/unstyled-button';
import { ClaimMethod } from '@paid-ui/enums/claim';
import { ContractTemplate } from '@paid-ui/enums/contract';
import { appConfigManager } from '@paid-ui/models/app-config';
import { contractManager } from '@paid-ui/models/contract';
import {
  closeDialog,
  dialogManager,
  DialogType,
  openDialog,
  restorePreviousDialog,
} from '@paid-ui/models/dialog';
import { userManager } from '@paid-ui/models/user';
import { type CSS, Dialog, useToast } from '@paid-ui/ui';
import { AlertDialog } from '@paid-ui/ui/alert-dialog';
import { useUpdateEffect } from 'ahooks';
import { useRouter } from 'next/router';
import { forwardRef, lazy as dynamic, Suspense, useCallback, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio/react';

import LoadingIndicator from './LoadingIndicator';

const upper = (qs: string | string[] | undefined) => {
  const q = Array.isArray(qs) ? qs[0] : qs;
  return q ? q.toUpperCase() : '';
};

const FeatureToggle = dynamic(() => import('@paid-ui/blocks/feature-toggle'));
const NewOrReviewPrimeContract = dynamic(() => import('@paid-ui/forms/contract/prime'));
const ReviewContract = dynamic(() => import('./ReviewContract'));
const NewLinking = dynamic(() => import('./NewLinking'));
const NewOrAmendVariation = dynamic(() => import('./NewOrAmendVariation'));
const ReviewVariation = dynamic(() => import('./ReviewVariation'));

const GlobalDialogContent = () => {
  const { type, details } = useSnapshot(dialogManager);
  const {
    id,
    statuses: { isOffPlatformContract },
  } = useSnapshot(contractManager);

  switch (type) {
    case DialogType.NEW_VARIATION: {
      return <NewOrAmendVariation type="VARIATION" />;
    }
    case DialogType.NEW_ADJUSTMENT: {
      return <NewOrAmendVariation type="ADJUSTMENT" />;
    }
    case DialogType.AMEND_VARIATION: {
      const { variationId } = details;
      return <NewOrAmendVariation id={variationId} type="VARIATION" />;
    }
    case DialogType.AMEND_ADJUSTMENT: {
      const { adjustmentId } = details;
      return <NewOrAmendVariation id={adjustmentId} type="ADJUSTMENT" />;
    }
    case DialogType.REVIEW_VARIATION: {
      const { variationId } = details;
      return <ReviewVariation id={variationId} type="VARIATION" />;
    }
    case DialogType.REVIEW_ADJUSTMENT: {
      const { adjustmentId } = details;
      return <ReviewVariation id={adjustmentId} type="ADJUSTMENT" />;
    }
    case DialogType.FEATURE_TOGGLE: {
      return <FeatureToggle />;
    }
    case DialogType.NEW_CONTRACT: {
      return <NewOrReviewPrimeContract />;
    }
    case DialogType.REVIEW_CONTRACT: {
      if (!id) return <LoadingIndicator />;
      return isOffPlatformContract ? <NewOrReviewPrimeContract isReview /> : <ReviewContract />;
    }
    case DialogType.NEW_LINKING: {
      const { progressPaymentId } = details;
      return <NewLinking progressPaymentId={progressPaymentId} />;
    }
    default: {
      return null;
    }
  }
};

export const GlobalDialog = forwardRef<HTMLDivElement>((props, ref) => {
  const toast = useToast();
  const router = useRouter();
  const { env } = useSnapshot(appConfigManager);
  const { account } = useSnapshot(userManager);
  const [openAccountAlert, setAccountAlertOpen] = useState(false);
  const [actionType, setActionType] = useState('claim');

  const { open, stack, details } = useSnapshot(dialogManager);
  const { closeOnClickOutside, closeOnEscapeKeyDown, closeCallback, nested, size, dialogStyle } =
    details;

  const handleApplyAccountAlertOK = () => {
    setAccountAlertOpen(false);
    setTimeout(() => {
      openApplyAccountDialog();
    }, 200);
  };

  const handleHelpCenterOpen = () => {
    setAccountAlertOpen(false);
    setTimeout(() => {
      openFlyout(FlyoutType.HELP_CENTER, {
        className: 'w-[752px]',
        showCloseIcon: true,
        closeOnClickOutside: true,
      });
    }, 200);
  };

  const handleDialogChange = useCallback(() => {
    if (stack.length > 0) {
      restorePreviousDialog();
    } else {
      closeDialog();
    }

    setTimeout(() => {
      if (typeof closeCallback === 'function') {
        closeCallback();
      }
    }, 150);
  }, [closeCallback, stack.length]);

  useEffect(() => {
    switch (router.query.dialog) {
      case 'feature-toggle': {
        if (['uat', 'prod'].includes(env)) return;
        openDialog(DialogType.FEATURE_TOGGLE, {
          closeOnClickOutside: false,
        });
        break;
      }

      case 'new-contract': {
        if (router.query.template === 'off_platform') {
          openDialog(DialogType.NEW_CONTRACT, {
            closeOnClickOutside: false,
          });
        }
        break;
      }

      case 'review-contract': {
        if (typeof router.query.contractId !== 'string') {
          toast.error('Missing contract ID url search param.');
          return;
        }

        if (upper(router.query.template).includes('RBN')) {
          return;
        }

        if (upper(router.query.template) === ContractTemplate.SUPER_CONTRACT) {
          return;
        }

        if (upper(router.query.method) === ClaimMethod.PERIODIC) {
          return;
        }

        openDialog(DialogType.REVIEW_CONTRACT, {
          closeOnClickOutside: false,
        });
        break;
      }

      case 'new-variation': {
        if (upper(router.query.method) === ClaimMethod.PERIODIC) return;
        openDialog(DialogType.NEW_VARIATION, {
          closeOnClickOutside: false,
        });
        break;
      }

      case 'new-adjustment': {
        openDialog(DialogType.NEW_ADJUSTMENT, {
          closeOnClickOutside: false,
        });
        break;
      }

      case 'amend-variation': {
        if (upper(router.query.method) === ClaimMethod.PERIODIC) {
          return;
        }

        const variationId = router.query.variationId || router.query.variation;
        if (!variationId) return;

        openDialog(DialogType.AMEND_VARIATION, {
          variationId,
          closeOnClickOutside: false,
          closeOnEscapeKeyDown: false,
        });
        break;
      }

      case 'amend-adjustment': {
        const adjustmentId = router.query.adjustmentId || router.query.adjustment;
        if (!adjustmentId) return;

        openDialog(DialogType.AMEND_ADJUSTMENT, {
          adjustmentId,
          closeOnClickOutside: false,
          closeOnEscapeKeyDown: false,
        });
        break;
      }

      case 'review-variation': {
        if (upper(router.query.method) === ClaimMethod.PERIODIC) {
          return;
        }

        const variationId = router.query.variationId || router.query.variation;
        if (!variationId) return;

        openDialog(DialogType.REVIEW_VARIATION, {
          variationId,
          closeOnClickOutside: false,
        });
        break;
      }

      case 'review-adjustment': {
        const adjustmentId = router.query.adjustmentId || router.query.adjustment;
        if (!adjustmentId) return;

        openDialog(DialogType.REVIEW_ADJUSTMENT, {
          adjustmentId,
          closeOnClickOutside: false,
        });
        break;
      }

      default: {
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.dialog, router.query.template, router.query.t]);

  useUpdateEffect(() => {
    if (!open) {
      const urlSearchParams = new URLSearchParams(window.location.search);

      urlSearchParams.delete('dialog');
      urlSearchParams.delete('t');
      urlSearchParams.delete('action');
      urlSearchParams.delete('step');
      urlSearchParams.delete('footer');
      urlSearchParams.delete('payment');
      urlSearchParams.delete('paymentId');
      urlSearchParams.delete('claim');
      urlSearchParams.delete('claimId');
      urlSearchParams.delete('adjustment');
      urlSearchParams.delete('adjustmentId');
      urlSearchParams.delete('variation');
      urlSearchParams.delete('variationId');
      urlSearchParams.delete('transaction');
      urlSearchParams.delete('transactionId');
      urlSearchParams.delete('contractType');
      urlSearchParams.delete('template');
      urlSearchParams.delete('method');
      urlSearchParams.delete('subTab');
      urlSearchParams.delete('provisional');
      urlSearchParams.delete('source');

      router.replace(
        {
          pathname: window.location.pathname,
          search: urlSearchParams.toString(),
        },
        undefined,
        { shallow: true },
      );
    }
  }, [open]);

  return (
    <>
      <Dialog
        ref={ref}
        open={open}
        size={size}
        nested={nested}
        onOpenChange={handleDialogChange}
        closeOnClickOutside={closeOnClickOutside}
        closeOnEscapeKeyDown={closeOnEscapeKeyDown}
        css={dialogStyle as CSS}
      >
        <Suspense fallback={<LoadingIndicator />}>
          <GlobalDialogContent />
        </Suspense>
      </Dialog>

      <AlertDialog
        customIcon="info"
        open={openAccountAlert}
        title="Transaction account required."
        description={
          account?.id ? (
            <div className="grid gap-6">
              <p>Your account application will be reviewed soon.</p>
              <p>
                <UnstyledButton
                  className="cursor-pointer font-medium text-blue no-underline"
                  onClick={handleHelpCenterOpen}
                >
                  Contact us
                </UnstyledButton>{' '}
                for any enquiries.
              </p>
            </div>
          ) : (
            `A Paid transaction account is required to ${actionType} progress payments.`
          )
        }
        buttonText={account?.id ? 'OK' : 'Apply for account'}
        onOk={handleApplyAccountAlertOK}
        onClose={() => setAccountAlertOpen(false)}
      />
    </>
  );
});

GlobalDialog.displayName = 'GlobalDialog';
