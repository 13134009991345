import {
  ClaimSource,
  ClaimStatus,
  ClaimStatusWithDetails,
  type ContractState,
  ContractType,
  PartyType,
  PaymentSecuredStatus,
} from '@paid-ui/constants';
import type { PaymentStage } from '@paid-ui/types';
import {
  formatCurrency,
  getClaimStatusColorForParty,
  getPaidPercent,
  getSecuredPercent,
  getStageName,
  getTotalSecuredAmount,
  getTotalSecuredAmountForPayment,
  isPaymentFullySecured,
} from '@paid-ui/utils';
import isNil from 'lodash/isNil';
import { forwardRef, useCallback, useMemo } from 'react';

import type { BaseProps } from '../interfaces';
import { Progress } from '../progress';
import { Skeleton } from '../skeleton';
import { Space } from '../space';
import {
  ActionButton,
  ArrowIcon,
  AutoWidthContainer,
  BottomGrid,
  BottomLeft,
  BottomRight,
  ClaimActionContainer,
  ClaimAmount,
  ClaimStatusContainer,
  ClaimStatusDot,
  ClaimStatusText,
  ClaimStatusWrap,
  DateIcon,
  DescriptionContainer,
  DescriptionLabel,
  LinkingButton,
  LinkingIcon,
  LinkingStatus,
  OffPlatformText,
  PaymentAmount,
  PaymentContainer,
  Percentage,
  StageName,
  TopGrid,
  Variation,
  VariationCount,
  VariationIcon,
} from './_Base';
import {
  getClaimStatusColor,
  getClaimStatusWithDetails,
  getDescriptionForClaim,
  getOverdueDays,
} from './_utils';

export interface ProgressPaymentProps extends BaseProps {
  type?: ContractType;
  viewAs?: PartyType;
  payment: PaymentStage;
  contractPrice?: number;
  contractState?: ContractState;
  showLinkingStatus?: boolean;
  showSecuredStatus?: boolean;
  loading?: boolean;
  submitting?: boolean;
  hidden?: boolean;
  isPayReady?: boolean;
  isDueToday?: boolean;
  enableLinkPayment?: boolean;
  enableSecureStart?: boolean;
  enableOfferReview?: boolean;
  onClick?: (payment: PaymentStage) => void;
  onLinkingClick?: (payment: PaymentStage) => void;
  onLinkingCreate?: (payment: PaymentStage) => void;
  onClaimCreate?: (payment: PaymentStage) => void;
  onClaimUpdate?: (payment: PaymentStage) => void;
  onClaimReview?: (payment: PaymentStage) => void;
  onClaimPay?: (payment: PaymentStage) => void;
  onMarkAsPaid?: (payment: PaymentStage) => void;
  onSecure?: (payment: PaymentStage) => void;
  onGetPaidNow?: (payment: PaymentStage) => void;
  onOfferReview?: (payment: PaymentStage) => void;
}

export interface PrimeProgressPaymentProps extends Omit<ProgressPaymentProps, 'type' | 'hidden'> {
  color?: 'default' | 'primary' | 'success' | 'danger';
}

export interface SecondaryProgressPaymentProps
  extends Omit<ProgressPaymentProps, 'type' | 'hidden'> {
  color?: 'default' | 'primary' | 'success' | 'danger';
}

export interface ClaimStatusIconComponentProps {
  claimStatusToDisplay: ClaimStatusWithDetails;
  securedValue?: number;
  paidValue?: number;
  width?: number;
}

export const ClaimStatusIconComponent: React.FC<ClaimStatusIconComponentProps> = (props) => {
  const { claimStatusToDisplay, paidValue = 0, securedValue = 0, width = 60 } = props;
  switch (claimStatusToDisplay) {
    case ClaimStatusWithDetails.NOT_YET_APPROVED: {
      return <ClaimStatusDot color="white" />;
    }
    case ClaimStatusWithDetails.PAID: {
      return <ClaimStatusDot color="green" />;
    }
    case ClaimStatusWithDetails.OVERDUE: {
      return <ClaimStatusDot color="pink" />;
    }
    case ClaimStatusWithDetails.SECURED: {
      return <ClaimStatusDot color="blue" />;
    }
    case ClaimStatusWithDetails.PART_PAID: {
      if (securedValue) {
        return (
          <AutoWidthContainer css={{ width: `${width}px` }}>
            <Progress
              value={paidValue + securedValue}
              linkedValue={paidValue}
              color="green"
              twoToneColor="grey"
              linkedStyle={{
                background: 'unset',
                backgroundColor: '#1a7dff',
                backgroundSize: '4px 100%',
              }}
              gap={0}
              compact
            />
          </AutoWidthContainer>
        );
      }
      return (
        <AutoWidthContainer css={{ width: `${width}px` }}>
          <Progress value={paidValue} color="green" gap={0} compact />
        </AutoWidthContainer>
      );
    }
    case ClaimStatusWithDetails.PART_SECURED: {
      return (
        <AutoWidthContainer css={{ width: `${width}px` }}>
          <Progress value={securedValue} gap={0} compact />
        </AutoWidthContainer>
      );
    }
    default: {
      return <ClaimStatusDot />;
    }
  }
};

export const getDisplayClaimDescription = (
  claimStatusToDisplay: ClaimStatusWithDetails,
  payment: PaymentStage,
) => {
  const claimStatusDescription = getDescriptionForClaim(claimStatusToDisplay, payment);
  if (claimStatusDescription.includes('Due')) {
    return (
      <DescriptionContainer>
        <DateIcon name="calendar" />
        <DescriptionLabel>{claimStatusDescription}</DescriptionLabel>
      </DescriptionContainer>
    );
  }
  return claimStatusDescription ? (
    <DescriptionLabel>{claimStatusDescription}</DescriptionLabel>
  ) : null;
};

const getTopGridComponent = (payment: PaymentStage, contractPrice = 0, isSecondary = false) => {
  const stageName = getStageName(payment);
  const adjustments = payment.claim?.adjustments ?? [];
  const variations = payment.claim?.variations ?? [];
  const numberOfVariations = variations.length + adjustments.length;
  const percentage = `${payment.percentOfContractSum}%`;
  return (
    <TopGrid secondary={isSecondary}>
      <StageName>
        <span>{stageName}</span>
        <Variation hidden={numberOfVariations === 0}>
          <span>+</span>
          <VariationIcon name="create-variation" />
          <VariationCount>{numberOfVariations}</VariationCount>
        </Variation>
      </StageName>
      <Percentage hidden={!!payment.claim?.id}>{percentage}</Percentage>
      <PaymentAmount empty={!contractPrice} bold={!!payment.claim?.amount}>
        {contractPrice ? formatCurrency(payment.claim?.amount ?? payment?.amount ?? 0) : '-'}
      </PaymentAmount>
    </TopGrid>
  );
};

export const PrimeProgressPayment = forwardRef<HTMLDivElement, PrimeProgressPaymentProps>(
  (props, ref) => {
    const {
      color,
      loading,
      submitting,
      viewAs = PartyType.PAYER,
      payment,
      isDueToday,
      contractPrice = 0,
      contractState,
      showLinkingStatus,
      showSecuredStatus,
      isPayReady,
      enableSecureStart,
      onClick,
      onLinkingClick,
      onLinkingCreate,
      onClaimCreate,
      onClaimReview,
      onClaimUpdate,
      onClaimPay,
      onMarkAsPaid,
      onSecure,
      ...restProps
    } = props;

    const stageName = getStageName(payment);
    const canSecure = Boolean(
      payment.secured === PaymentSecuredStatus.NOT_SECURED ||
        payment.secured === PaymentSecuredStatus.PARTIALLY_SECURED,
    );
    const fullySecured = isPaymentFullySecured(payment);
    const claimStatusToDisplay = getClaimStatusWithDetails(payment);
    const claimStatusColor = getClaimStatusColor(claimStatusToDisplay);
    const claimStatusOverdueDays = getOverdueDays(claimStatusToDisplay, payment);
    const numberOfLinked = payment.linked?.length ?? 0;

    const handleClick = useCallback(() => {
      if (typeof onClick === 'function') {
        onClick(payment);
      }
    }, [onClick, payment]);

    const handleClaimCreate = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (typeof onClaimCreate === 'function') {
          onClaimCreate(payment);
        }
      },
      [onClaimCreate, payment],
    );

    const handleClaimUpdate = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (typeof onClaimUpdate === 'function') {
          onClaimUpdate(payment);
        }
      },
      [onClaimUpdate, payment],
    );

    const handleClaimReview = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (typeof onClaimReview === 'function') {
          onClaimReview(payment);
        }
      },
      [onClaimReview, payment],
    );

    const handleMarkAsPaid = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (typeof onMarkAsPaid === 'function') {
          onMarkAsPaid(payment);
        }
      },
      [onMarkAsPaid, payment],
    );

    const handleClaimPay = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (typeof onClaimPay === 'function') {
          onClaimPay(payment);
        }
      },
      [onClaimPay, payment],
    );

    const handleSecurePayment = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (typeof onSecure === 'function') {
          onSecure(payment);
        }
      },
      [onSecure, payment],
    );

    const actions = useMemo(() => {
      if (payment.disabled) {
        return null;
      }

      if (
        payment.claim &&
        payment.claim.source === ClaimSource.OFF_PLATFORM &&
        payment.claim.state === ClaimStatus.APPROVED
      ) {
        return viewAs === PartyType.PAYEE ? (
          <ActionButton color="primary" loading={submitting} onClick={handleMarkAsPaid}>
            {submitting ? 'Submitting...' : 'Mark as paid'}
          </ActionButton>
        ) : null;
      }

      switch (payment.claim?.state) {
        case ClaimStatus.SUBMITTED:
        case ClaimStatus.RESUBMITTED: {
          return viewAs === PartyType.PAYER ? (
            <ClaimActionContainer>
              <ArrowIcon name="next" />
              <ActionButton color="primary" onClick={handleClaimReview}>
                Review
              </ActionButton>
            </ClaimActionContainer>
          ) : null;
        }

        case ClaimStatus.REJECTED: {
          return viewAs === PartyType.PAYEE ? (
            <ClaimActionContainer>
              <ArrowIcon name="next" />
              <ActionButton color="primary" onClick={handleClaimUpdate}>
                Resubmit
              </ActionButton>
            </ClaimActionContainer>
          ) : null;
        }

        case ClaimStatus.APPROVED: {
          return viewAs === PartyType.PAYER && isPayReady && !fullySecured ? (
            <ClaimActionContainer>
              <ArrowIcon name="next" />
              <ActionButton color="primary" onClick={handleClaimPay}>
                Pay
              </ActionButton>
            </ClaimActionContainer>
          ) : null;
        }

        case ClaimStatus.PAID: {
          return null;
        }

        default: {
          if (viewAs === PartyType.PAYER) {
            const hideSecure = !enableSecureStart || !canSecure;
            return (
              <ActionButton
                color="primary"
                variant="outline"
                onClick={handleSecurePayment}
                hidden={hideSecure}
              >
                Secure
              </ActionButton>
            );
          } else if (viewAs === PartyType.PAYEE) {
            return (
              <ActionButton color="primary" variant="outline" onClick={handleClaimCreate}>
                Claim
              </ActionButton>
            );
          }
        }
      }
    }, [
      payment.disabled,
      payment.claim,
      viewAs,
      submitting,
      handleMarkAsPaid,
      handleClaimReview,
      handleClaimUpdate,
      isPayReady,
      fullySecured,
      handleClaimPay,
      enableSecureStart,
      canSecure,
      handleSecurePayment,
      handleClaimCreate,
    ]);

    const secureIcon = useMemo(() => {
      if (!showSecuredStatus || payment.claim?.state === ClaimStatus.APPROVED) {
        return null;
      }

      if (getTotalSecuredAmountForPayment(payment) === 0) {
        return null;
      }

      const { paymentBreakdown } = payment.claim ?? {};

      if (
        payment.secured === PaymentSecuredStatus.PARTIALLY_SECURED ||
        paymentBreakdown?.unsecuredUnpaid
      ) {
        return <LinkingIcon name="secured" />;
      }

      if (payment.secured === PaymentSecuredStatus.SECURED) {
        return <LinkingIcon name="secured" blue />;
      }
      return null;
    }, [payment, showSecuredStatus]);

    const secureText = useMemo(() => {
      const { state, amount, paymentBreakdown } = payment.claim ?? {};

      if (!showSecuredStatus || state === ClaimStatus.APPROVED) {
        return '';
      }

      const total = getTotalSecuredAmountForPayment(payment);

      if (
        payment.secured === PaymentSecuredStatus.PARTIALLY_SECURED ||
        (paymentBreakdown?.securedUnreleased &&
          amount &&
          paymentBreakdown.securedUnreleased < amount)
      ) {
        return total > 0 ? formatCurrency(total) : '';
      }

      if (payment.secured === PaymentSecuredStatus.SECURED) {
        return total ? 'Secured' : '';
      }

      return '';
    }, [payment, showSecuredStatus]);

    return (
      <PaymentContainer
        ref={ref}
        role="button"
        aria-label={stageName}
        onClick={handleClick}
        {...restProps}
      >
        {getTopGridComponent(payment, contractPrice)}
        <BottomGrid>
          <BottomLeft>
            <ClaimStatusContainer>
              <ClaimStatusWrap>
                <ClaimStatusIconComponent
                  claimStatusToDisplay={claimStatusToDisplay}
                  paidValue={getPaidPercent(payment, true)}
                  securedValue={getSecuredPercent(payment)}
                />
                <ClaimStatusText color={claimStatusColor}>
                  {claimStatusToDisplay}
                  {claimStatusOverdueDays}
                </ClaimStatusText>
                {getDisplayClaimDescription(claimStatusToDisplay, payment)}
              </ClaimStatusWrap>
              <ClaimStatusWrap>
                {showLinkingStatus && viewAs === PartyType.PAYEE ? (
                  <LinkingButton hidden={numberOfLinked === 0}>
                    <LinkingIcon name="links" size={15} />
                    <LinkingStatus>{numberOfLinked}</LinkingStatus>
                  </LinkingButton>
                ) : null}
                <LinkingButton>
                  {secureIcon}
                  <LinkingStatus secured={fullySecured}>{secureText}</LinkingStatus>
                </LinkingButton>
                {payment.claim?.source === ClaimSource.OFF_PLATFORM ? (
                  <OffPlatformText>Off platform</OffPlatformText>
                ) : null}
              </ClaimStatusWrap>
            </ClaimStatusContainer>
          </BottomLeft>
          <BottomRight>{actions}</BottomRight>
        </BottomGrid>
      </PaymentContainer>
    );
  },
);

export const SecondaryProgressPayment = forwardRef<HTMLDivElement, SecondaryProgressPaymentProps>(
  (props, ref) => {
    const {
      color,
      loading,
      submitting,
      viewAs = PartyType.PAYER,
      payment,
      contractPrice = 0,
      contractState,
      showLinkingStatus,
      isPayReady,
      isDueToday,
      enableLinkPayment,
      enableSecureStart,
      enableOfferReview,
      showSecuredStatus,
      onClick,
      onLinkingClick,
      onLinkingCreate,
      onClaimCreate,
      onClaimReview,
      onClaimUpdate,
      onClaimPay,
      onGetPaidNow,
      onMarkAsPaid,
      onSecure,
      onOfferReview,
      ...restProps
    } = props;

    const stageName = getStageName(payment);
    const hasParent = Boolean(payment.parent?.id);
    const canSecure = Boolean(
      payment.secured === PaymentSecuredStatus.NOT_SECURED ||
        payment.secured === PaymentSecuredStatus.PARTIALLY_SECURED,
    );
    const fullySecured = isPaymentFullySecured(payment);
    const canRelease =
      !isNil(payment.claim?.earlyReleaseDiscountRate) &&
      Boolean(
        payment.secured === PaymentSecuredStatus.SECURED ||
          payment.secured === PaymentSecuredStatus.PARTIALLY_SECURED,
      ) &&
      getTotalSecuredAmount(payment.securedProgressPayments) > 0;
    const canReviewOffer = canRelease;
    const claimStatusToDisplay = getClaimStatusWithDetails(payment);
    const claimStatusColor = getClaimStatusColor(claimStatusToDisplay);
    const claimStatusOverdueDays = getOverdueDays(claimStatusToDisplay, payment);

    const handleClick = useCallback(() => {
      if (typeof onClick === 'function') {
        onClick(payment);
      }
    }, [onClick, payment]);

    const handleLinkingCreate = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (typeof onLinkingCreate === 'function') {
          onLinkingCreate(payment);
        }
      },
      [onLinkingCreate, payment],
    );

    const handleClaimCreate = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (typeof onClaimCreate === 'function') {
          onClaimCreate(payment);
        }
      },
      [onClaimCreate, payment],
    );

    const handleClaimUpdate = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (typeof onClaimUpdate === 'function') {
          onClaimUpdate(payment);
        }
      },
      [onClaimUpdate, payment],
    );

    const handleClaimReview = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (typeof onClaimReview === 'function') {
          onClaimReview(payment);
        }
      },
      [onClaimReview, payment],
    );

    const handleClaimPay = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (typeof onClaimPay === 'function') {
          onClaimPay(payment);
        }
      },
      [onClaimPay, payment],
    );

    const handleOfferReview = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (typeof onOfferReview === 'function') {
          onOfferReview(payment);
        }
      },
      [onOfferReview, payment],
    );

    const handleSecurePayment = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (typeof onSecure === 'function') {
          onSecure(payment);
        }
      },
      [onSecure, payment],
    );

    const handleGetPaidNow = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (typeof onGetPaidNow === 'function') {
          onGetPaidNow(payment);
        }
      },
      [onGetPaidNow, payment],
    );

    const actions = useMemo(() => {
      if (payment.disabled) {
        return null;
      }

      switch (payment.claim?.state) {
        case ClaimStatus.SUBMITTED:
        case ClaimStatus.RESUBMITTED: {
          return viewAs === PartyType.PAYER ? (
            <ClaimActionContainer>
              <ArrowIcon name="next" />
              <ActionButton color="primary" onClick={handleClaimReview}>
                Review
              </ActionButton>
            </ClaimActionContainer>
          ) : null;
        }

        case ClaimStatus.REJECTED: {
          return viewAs === PartyType.PAYEE ? (
            <ClaimActionContainer>
              <ArrowIcon name="next" />
              <ActionButton color="primary" onClick={handleClaimUpdate}>
                Resubmit
              </ActionButton>
            </ClaimActionContainer>
          ) : null;
        }

        case ClaimStatus.APPROVED: {
          if (viewAs === PartyType.PAYER) {
            return (
              <>
                <ClaimActionContainer hidden={!isPayReady || fullySecured}>
                  <ArrowIcon name="next" />
                  <ActionButton color="primary" onClick={handleClaimPay}>
                    Pay
                  </ActionButton>
                </ClaimActionContainer>
                <ClaimActionContainer hidden={!enableOfferReview || !canReviewOffer}>
                  <ArrowIcon name="next" />
                  <ActionButton color="primary" onClick={handleOfferReview}>
                    Review offer
                  </ActionButton>
                </ClaimActionContainer>
              </>
            );
          }

          return enableOfferReview ? (
            <ClaimActionContainer hidden={!canReviewOffer}>
              <ArrowIcon name="next" />
              <ActionButton color="primary" onClick={handleOfferReview}>
                Review offer
              </ActionButton>
            </ClaimActionContainer>
          ) : (
            <ActionButton
              color="primary"
              variant="outline"
              onClick={handleGetPaidNow}
              hidden={!canRelease || isDueToday}
            >
              Get InstaPaid
            </ActionButton>
          );
        }

        case ClaimStatus.PAID: {
          return null;
        }

        default: {
          if (viewAs === PartyType.PAYER) {
            const hideSecure = !enableSecureStart || !canSecure || hasParent;
            const hideLink = !enableLinkPayment || hasParent || fullySecured;
            if (hideSecure && hideLink) {
              return null;
            }
            return (
              <Space size={12} css={{ ml: 'auto' }}>
                <ActionButton
                  color="primary"
                  variant="outline"
                  onClick={handleSecurePayment}
                  hidden={hideSecure}
                >
                  Secure
                </ActionButton>
                <ActionButton
                  color="primary"
                  variant="outline"
                  onClick={handleLinkingCreate}
                  hidden={hideLink}
                >
                  Link
                </ActionButton>
              </Space>
            );
          } else if (viewAs === PartyType.PAYEE) {
            return (
              <ActionButton color="primary" variant="outline" onClick={handleClaimCreate}>
                Claim
              </ActionButton>
            );
          }

          return null;
        }
      }
    }, [
      payment.disabled,
      payment.claim?.state,
      viewAs,
      handleClaimReview,
      handleClaimUpdate,
      enableOfferReview,
      canReviewOffer,
      handleOfferReview,
      handleGetPaidNow,
      canRelease,
      isDueToday,
      isPayReady,
      fullySecured,
      handleClaimPay,
      enableSecureStart,
      canSecure,
      hasParent,
      enableLinkPayment,
      handleSecurePayment,
      handleLinkingCreate,
      handleClaimCreate,
    ]);

    const linkedIcon = useMemo(() => {
      if (payment.secured === PaymentSecuredStatus.NOT_SECURED) {
        if (!showLinkingStatus) {
          return null;
        }
        if (payment.parent?.id) {
          return <LinkingIcon name="payment-linked" />;
        }
        return null;
      }
      if (!showSecuredStatus || payment.claim?.state === ClaimStatus.APPROVED) {
        return null;
      }
      if (getTotalSecuredAmountForPayment(payment) === 0) {
        return null;
      }
      const { paymentBreakdown } = payment.claim ?? {};
      if (
        payment.secured === PaymentSecuredStatus.PARTIALLY_SECURED ||
        paymentBreakdown?.unsecuredUnpaid
      ) {
        return <LinkingIcon name="secured" />;
      }
      if (payment.secured === PaymentSecuredStatus.SECURED) {
        return <LinkingIcon name="secured" blue />;
      }
      return null;
    }, [payment, showLinkingStatus, showSecuredStatus]);

    const linkedText = useMemo(() => {
      if (payment.secured === PaymentSecuredStatus.NOT_SECURED) {
        if (!showLinkingStatus) {
          return '';
        }
        return payment?.parent?.name ?? '';
      }
      const { state, amount, paymentBreakdown } = payment.claim ?? {};
      if (!showSecuredStatus || state === ClaimStatus.APPROVED) {
        return '';
      }
      const total = getTotalSecuredAmountForPayment(payment);
      if (
        payment.secured === PaymentSecuredStatus.PARTIALLY_SECURED ||
        (paymentBreakdown?.securedUnreleased &&
          amount &&
          paymentBreakdown.securedUnreleased < amount)
      ) {
        return total > 0 ? formatCurrency(total) : '';
      }
      if (payment.secured === PaymentSecuredStatus.SECURED) {
        return total ? 'Secured' : '';
      }
      return '';
    }, [payment, showLinkingStatus, showSecuredStatus]);

    return (
      <PaymentContainer
        ref={ref}
        role="button"
        aria-label={stageName}
        onClick={handleClick}
        {...restProps}
      >
        {getTopGridComponent(payment, contractPrice, true)}
        <BottomGrid>
          <BottomLeft>
            <ClaimStatusContainer>
              <ClaimStatusWrap>
                <ClaimStatusIconComponent
                  claimStatusToDisplay={claimStatusToDisplay}
                  paidValue={getPaidPercent(payment, true)}
                  securedValue={getSecuredPercent(payment)}
                />
                <ClaimStatusText color={claimStatusColor}>
                  {claimStatusToDisplay}
                  {claimStatusOverdueDays}
                </ClaimStatusText>
              </ClaimStatusWrap>
              <ClaimStatusWrap>
                {getDisplayClaimDescription(claimStatusToDisplay, payment)}
                <LinkingButton>
                  {linkedIcon}
                  <LinkingStatus secured={fullySecured}>{linkedText}</LinkingStatus>
                </LinkingButton>
              </ClaimStatusWrap>
            </ClaimStatusContainer>
          </BottomLeft>
          <BottomRight>{actions}</BottomRight>
        </BottomGrid>
      </PaymentContainer>
    );
  },
);

export const ProgressPayment = forwardRef<HTMLDivElement, ProgressPaymentProps>((props, ref) => {
  const {
    type = ContractType.HEAD_CONTRACT,
    viewAs,
    payment,
    loading,
    hidden,
    ...restProps
  } = props;
  const color = getClaimStatusColorForParty(payment, viewAs);

  if (hidden) {
    return null;
  }

  if (loading) {
    return (
      <PaymentContainer ref={ref} role="button">
        <TopGrid>
          <StageName>
            <Skeleton type="rect" height={22} width={120} />
          </StageName>
          <Percentage>
            <Skeleton type="rect" height={22} width={40} />
          </Percentage>
          <PaymentAmount
            css={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Skeleton type="rect" height={22} width={100} />
          </PaymentAmount>
        </TopGrid>
        <BottomGrid>
          <BottomLeft>
            <ClaimAmount>
              <Skeleton type="rect" height={16} width={80} />
            </ClaimAmount>
          </BottomLeft>
          <BottomRight
            css={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Skeleton type="rect" height={32} width={70} />
          </BottomRight>
        </BottomGrid>
      </PaymentContainer>
    );
  }

  if (type === ContractType.HEAD_CONTRACT) {
    return (
      <PrimeProgressPayment
        ref={ref}
        color={color}
        viewAs={viewAs}
        payment={payment}
        {...restProps}
      />
    );
  }

  return (
    <SecondaryProgressPayment
      ref={ref}
      color={color}
      viewAs={viewAs}
      payment={payment}
      {...restProps}
    />
  );
});

PrimeProgressPayment.displayName = 'PrimeProgressPayment';
SecondaryProgressPayment.displayName = 'SecondaryProgressPayment';
ProgressPayment.displayName = 'ProgressPayment';
