export enum WorkItemStatus {
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  // new added values for variation only
  RESUBMITTED = 'RESUBMITTED',
  REJECTED = 'REJECTED',
  ABANDONED = 'ABANDONED',
}

export enum WorkItemPaymentStatus {
  SUBMITTED = 'SUBMITTED',
  ACCEPTED = 'ACCEPTED',
  ADJUSTED = 'ADJUSTED',
}

export enum WorkItemType {
  LINE = 'LINE',
  VARIATION = 'VARIATION',
}

export enum AssessStatus {
  NOT_ASSESSED = 'NOT_ASSESSED',
  USER_ASSESSED = 'USER_ASSESSED',
  AUTO_ASSESSED = 'AUTO_ASSESSED',
  USER_APPROVED = 'USER_APPROVED',
}

export enum WorkItemTag {
  COST_OF_WORKS = 'COST_OF_WORKS',
  CONTRACTOR_FEE = 'CONTRACTOR_FEE',
}
