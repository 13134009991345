import { type PaymentStage } from '@paid-ui/types';

import { contractManager } from '../model';
import { formatProgressPayment } from './formatProgressPayment';

export const saveProvisionalClaims = (claims: PaymentStage[]) => {
  contractManager.numberOfProvisionalClaims = claims.length;
  contractManager.provisionalClaims = claims.map((claim, index) => {
    return formatProgressPayment(claim, {
      index,
      isProvisional: true,
    });
  });
};
