import { forwardRef, useEffect, useRef, useState } from 'react';

import { Input, type InputProps } from '../input';

/**
 * Format a value to a specific decimal place
 *
 * @param value - The value to format
 * @param options - Options for formatting
 * @param options.decimal - The number of decimal places to use
 * @param options.withSign - With percentage sign or not
 * @returns Formatted decimal string
 */
export function formatPercentage(
  value: string | number = '',
  options?: {
    decimal?: 0 | 1 | 2;
    withSign?: boolean;
  },
) {
  const { decimal = 1, withSign = false } = options ?? {};

  if (value === '') {
    return '';
  }

  const valueAsNumber = typeof value === 'string' ? Number.parseFloat(value) : value;

  if (Number.isNaN(valueAsNumber)) {
    return '';
  }

  return valueAsNumber.toFixed(decimal) + (withSign ? '%' : '');
}

export interface PercentageInputProps
  extends Omit<
    InputProps,
    'type' | 'inputMode' | 'step' | 'autoComplete' | 'autoCapitalize' | 'autoCorrect'
  > {
  value?: string | number;
  defaultValue?: string | number;
  decimal?: 0 | 1 | 2;
}

export const PercentageInput = forwardRef<HTMLInputElement, PercentageInputProps>(
  (
    {
      value,
      defaultValue,
      min = 0,
      max = 100,
      decimal = 1,
      placeholder = Number(0).toFixed(decimal),
      suffix = '%',
      onBlur,
      onChange,
      onKeyDown,
      className,
      ...restProps
    },
    ref,
  ) => {
    const mountedRef = useRef(false);
    const [displayValue, setDisplayValue] = useState(
      formatPercentage(value ?? defaultValue, {
        decimal,
      }),
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const valueAsNumber = Number.parseFloat(event.currentTarget.value);

      if (Number.isNaN(valueAsNumber)) {
        event.currentTarget.value = '';
        setDisplayValue('');
        return;
      }

      setDisplayValue(event.currentTarget.value);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      let newValue = '';

      const minValue = 5 / 10 ** (1 + decimal);
      const maxValue = 100 - minValue;
      const valueAsNumber = Number.parseFloat(event.currentTarget.value);

      if (Number.isNaN(valueAsNumber)) {
        newValue = '';
      } else if (valueAsNumber < minValue) {
        newValue = '0.0';
      } else if (min && valueAsNumber < Number(min)) {
        newValue = min.toString();
      } else if (Number(max) === 0) {
        newValue = '0.0';
      } else if (Number(max) > 0 && valueAsNumber > Number(max)) {
        newValue = max.toString();
      } else if (Number(max) > 0 && valueAsNumber >= maxValue) {
        newValue = '100';
      } else {
        newValue = valueAsNumber.toFixed(decimal);
      }

      event.currentTarget.value = newValue;
      onChange?.(event);
      onBlur?.(event);
      setDisplayValue(newValue);
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
      if (['Tab', 'Enter'].includes(event.key)) {
        event.currentTarget.blur();
      }
      onKeyDown?.(event);
    };

    useEffect(() => {
      if (mountedRef.current) {
        setDisplayValue(
          formatPercentage(value, {
            decimal,
          }),
        );
      } else {
        mountedRef.current = true;
      }
    }, [decimal, value]);

    return (
      <Input
        ref={ref}
        value={displayValue}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        className={className}
        {...restProps}
        inputMode="decimal"
        min={min}
        step={1}
        max={max}
        placeholder={placeholder}
        autoComplete="off"
        autoCapitalize="none"
        autoCorrect="off"
        suffix={suffix}
      />
    );
  },
);

PercentageInput.displayName = 'PercentageInput';
