import { ContractsSummary } from '@paid-ui/blocks/dashboard/contracts-summary';
import { DashboardGuide } from '@paid-ui/blocks/dashboard/dashboard-guide';
import { ProjectCards } from '@paid-ui/blocks/dashboard/project-cards';
import { NewBasicLayout } from '@paid-ui/layouts/basic';
import { DashboardLayout } from '@paid-ui/layouts/dashboard';
import { requiringActionManager } from '@paid-ui/models/requiring-action';
import { useGetProjectList } from '@paid-ui/queries/project-list';
import type { NextPageWithAddons } from 'next';
import { NextSeo } from 'next-seo';
import { useSnapshot } from 'valtio/react';

const DashboardPage: NextPageWithAddons = () => {
  const { data = [], isLoading } = useGetProjectList();
  const { numberOfProjectActions } = useSnapshot(requiringActionManager);

  return (
    <DashboardLayout
      sidebar={<DashboardGuide numberOfProjects={data.length} loading={isLoading} />}
    >
      <NextSeo title="Dashboard" />
      <div className="space-y-9">
        <ContractsSummary numberOfProjects={data.length} />
        <ProjectCards data={data} loading={isLoading} badges={numberOfProjectActions} />
      </div>
    </DashboardLayout>
  );
};

DashboardPage.auth = true;
DashboardPage.layout = (page) => <NewBasicLayout>{page}</NewBasicLayout>;

export default DashboardPage;
