import { contractManager } from '@paid-ui/models/contract';
import {
  saveAccountSummary,
  stopLoading,
  transactionAccountManager,
} from '@paid-ui/models/transaction-account';
import { type AccountSummary } from '@paid-ui/types/payments';
import { request } from '@paid-ui/utils/axios';
import { useQuery } from '@tanstack/react-query';
import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio/react';

type GetTransactionAccountSummaryResponse = {
  data: AccountSummary;
} | null;

export const useTransactionAccountSetup = (enableAccount?: boolean) => {
  const mountedRef = useRef(false);
  const pathname = usePathname();
  const { bsb, accountNumber, forceUpdate } = useSnapshot(transactionAccountManager);
  const enabled = enableAccount && Boolean(bsb && accountNumber);

  const query = useQuery({
    retry: false,
    enabled,
    refetchOnWindowFocus: pathname === '/account',
    queryKey: ['GetTransactionAccountSummary', enableAccount, bsb, accountNumber],
    queryFn: async () => {
      const { data } = await request.get<GetTransactionAccountSummaryResponse>(
        `/transaction-account/${bsb}/${accountNumber}/summary`,
      );
      return data?.data ?? null;
    },
  });

  useEffect(() => {
    if (!mountedRef.current) return;
    if (!bsb || !accountNumber) return;
    query.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate]);

  useEffect(() => {
    if (!query.data || query.isFetching) return;
    mountedRef.current = true;
    saveAccountSummary(query.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.dataUpdatedAt]);

  useEffect(() => {
    if (enabled) return;
    stopLoading();
  }, [enabled]);

  return query;
};

export const useGetPartyAccountSummary = () => {
  const { currentParty } = useSnapshot(contractManager);
  const partyId = currentParty?.id;

  return useQuery({
    enabled: Boolean(partyId),
    queryKey: ['GetPartyAccountSummary', partyId],
    queryFn: async () => {
      const { data } = await request.get<GetTransactionAccountSummaryResponse>(
        `/parties/${partyId}/account-summary`,
      );
      return data?.data ?? null;
    },
  });
};
