import { type AccountSummary } from '@paid-ui/types';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

import { userManager } from './user';

const initialState = {
  // This id is account matcher ID
  id: '',
  accountId: '',
  availableBalance: 0,
  reservedBalance: 0,
  totalBalance: 0,
  bsb: '',
  accountNumber: '',
  accountCreationTime: '',
  /** Force update flag */
  forceUpdate: 0,
  loading: true,
};

export const retentionAccountManager = proxy(initialState);

devtools(retentionAccountManager, {
  name: 'Retention Transaction Account',
  enabled: false,
});

export const resetRetentionAccount = () => {
  Object.assign(retentionAccountManager, initialState);
};

export const saveRetentionAccount = (data: AccountSummary) => {
  retentionAccountManager.availableBalance = data.availableBalance;
  retentionAccountManager.reservedBalance = data.reservedBalance;
  retentionAccountManager.totalBalance = data.totalBalance;
  retentionAccountManager.bsb = data.bsb;
  retentionAccountManager.accountNumber = data.accountNumber;
  if (data.accountCreationTime) {
    retentionAccountManager.accountCreationTime = data.accountCreationTime;
  }
  retentionAccountManager.loading = false;
};

export const reloadRetentionAccount = () => {
  const { forceUpdate } = retentionAccountManager;
  const { isAccountReady } = userManager;
  if (!isAccountReady) return;
  retentionAccountManager.loading = true;
  retentionAccountManager.forceUpdate = forceUpdate > 100 ? 1 : forceUpdate + 1;
};

export const stopLoading = () => {
  retentionAccountManager.loading = false;
};
