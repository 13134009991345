export enum ContractClass {
  OFFPLATFORM_PRIME = 'OFFPLATFORM_PRIME',
  OFFPLATFORM_SUB = 'OFFPLATFORM_SUB',
  OFFPLATFORM_SUPPLY = 'OFFPLATFORM_SUPPLY',
  ONPLATFORM_PRIME_RBN = 'OFFPLATFORM_PRIME_RBN',
  ONPLATFORM_PRIME_QBCC = 'OFFPLATFORM_PRIME_QBCC',
  ONPLATFORM_SUB_PAID = 'ONPLATFORM_SUB_PAID',
  ONPLATFORM_SUPPLY_PAID = 'ONPLATFORM_SUPPLY_PAID',
  SUPER_CONTRACT = 'SUPER_CONTRACT',
}

export enum ContractTemplate {
  RBN = 'RBN',
  RBN_HIBC = 'RBN_HIBC',
  QBCC = 'QBCC',
  SUBCONTRACT = 'SUBCONTRACT',
  SUPPLY_CONTRACT = 'SUPPLY_CONTRACT',
  OFF_PLATFORM = 'OFF_PLATFORM',
  OFF_PLATFORM_SUBCONTRACT = 'OFF_PLATFORM_SUBCONTRACT',
  OFF_PLATFORM_SUPPLY_CONTRACT = 'OFF_PLATFORM_SUPPLY_CONTRACT',
  SUPER_CONTRACT = 'SUPER_CONTRACT',
}

export enum ContractSource {
  ON_PLATFORM = 'ON_PLATFORM',
  OFF_PLATFORM = 'OFF_PLATFORM',
}

export enum ContractCreateMethod {
  NEW_BUILDING = 'NEW_BUILDING',
  ONBOARD_SIGNED = 'ONBOARD_SIGNED',
}

export enum ContractType {
  HEAD_CONTRACT = 'HEAD_CONTRACT',
  SUBCONTRACT = 'SUBCONTRACT',
  SUPPLY_CONTRACT = 'SUPPLY_CONTRACT',
  SERVICE_CONTRACT = 'SERVICE_CONTRACT',
}

export enum ContractState {
  SUBMITTED = 'SUBMITTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  RESUBMITTED = 'RESUBMITTED',
  EXECUTED = 'EXECUTED',
  ABANDONED = 'ABANDONED',
  ALL_PAID = 'ALL_PAID',
  TERMINATED = 'TERMINATED',
}

export enum BaseOnCompletionOf {
  THIS_CONTRACT = 'THIS_CONTRACT',
  PRIME_CONTRACT = 'PRIME_CONTRACT',
}

export enum DefaultContractActionType {
  NEW_CLAIM = 'NEW_CLAIM',
  NEW_VARIATION = 'NEW_VARIATION',
  SET_COMMENCEMENT = 'SET_COMMENCEMENT',
  SET_COMPLETION = 'SET_COMPLETION',
  EDIT_DRAFT = 'EDIT_DRAFT',
  RESEND_INVITATION = 'RESEND_INVITATION',
  REINVITE_SUPERINTENDENT = 'REINVITE_SUPERINTENDENT',
  REINVITE_ARCHITECT = 'REINVITE_ARCHITECT',
  VIEW_CONTRACT = 'VIEW_CONTRACT',
  ABANDON_CONTRACT = 'ABANDON_CONTRACT',
  DELETE_DRAFT = 'DELETE_DRAFT',
  HALT_SIGNING_CONTRACT = 'HALT_SIGNING_CONTRACT',
}

export enum ContractRole {
  PRINCIPAL = 'PRINCIPAL',
  SUPERINTENDENT = 'SUPERINTENDENT',
  QUANTITY_SURVEYOR = 'QUANTITY_SURVEYOR',
  BUILDER = 'BUILDER',
  CONTRACTOR = 'CONTRACTOR',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  SUPPLIER = 'SUPPLIER',
  ARCHITECT = 'ARCHITECT',
}

export enum ContractActionType {
  CREATE = 'CREATE',
  AMEND = 'AMEND',
  REVIEW = 'REVIEW',
}

export enum ContractCreateMethod {
  ON_PLATFORM = 'ON_PLATFORM',
  OFF_PLATFORM = 'OFF_PLATFORM',
}

export enum PaymentAuthorisationStatus {
  AUTHORISED = 'AUTHORISED',
  NOT_AUTHORISED = 'NOT_AUTHORISED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum ContractRequirementStatus {
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
}

export enum ContractRequirementType {
  DEPOSIT_INSURANCE = 'DEPOSIT_INSURANCE',
  SIGNED_VARIATION = 'SIGNED_VARIATION',
  DISABLED_VARIATION = 'DISABLED_VARIATION',
  SIGNED_CLAIM = 'SIGNED_CLAIM',
}
