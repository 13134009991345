import { ContractRole } from '@paid-ui/enums/contract';
import { BrushIcon } from '@paid-ui/icons/brush';
import { DeliveryTruckIcon } from '@paid-ui/icons/delivery-truck';
import { HelmetIcon } from '@paid-ui/icons/helmet';
import { OwnerKeyIcon } from '@paid-ui/icons/owner-key';
import { ArchitectIcon } from '@paid-ui/icons/sketch';

export const contractRoleIconMap = {
  [ContractRole.PRINCIPAL]: OwnerKeyIcon,
  [ContractRole.CONTRACTOR]: HelmetIcon,
  [ContractRole.BUILDER]: HelmetIcon,
  [ContractRole.SUBCONTRACTOR]: BrushIcon,
  [ContractRole.SUPPLIER]: DeliveryTruckIcon,
  [ContractRole.ARCHITECT]: ArchitectIcon,
  [ContractRole.SUPERINTENDENT]: ArchitectIcon,
  [ContractRole.QUANTITY_SURVEYOR]: ArchitectIcon,
};
