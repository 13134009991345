import { ClaimMethod } from '@paid-ui/enums/claim';
import { ContractTemplate, ContractType } from '@paid-ui/enums/contract';
import { PaymentTermsType } from '@paid-ui/enums/payment';
import { SupercontractCode } from '@paid-ui/enums/supercontract';
import { projectManager } from '@paid-ui/models/project';
import { type HeadContract } from '@paid-ui/types';
import { formatAddress } from '@paid-ui/utils/formatter';

import { contractManager } from '../model';
import { type Contract } from '../types';
import { saveAttachments } from './saveAttachments';
import { saveContractBreakdown } from './saveContractBreakdown';
import { saveContractPrice, saveCostOfWorks } from './saveContractPrice';
import { saveDefaultActions } from './saveDefaultActions';
import { saveGstSettings } from './saveGstSettings';
import { saveInsurances } from './saveInsurances';
import { saveLoanAndLandOwner } from './saveLoanAndLandOwner';
import { saveParticipantParties } from './saveParticipantParties';
import { savePeriodicClaims } from './savePeriodicClaims';
import { savePeriodicPermissions } from './savePeriodicPermissions';
import { savePermissions } from './savePermissions';
import { saveProgressPayments } from './saveProgressPayments';
import { saveRequiringActions } from './saveRequiringActions';
import { saveRetentions } from './saveRetentions';
import { saveRolesAndResponsibilities } from './saveRolesAndResponsibilities';
import { saveStatuses } from './saveStatuses';
import { saveTimeline } from './saveTimeline';
import {
  savePeriodicVariations,
  saveVariationsAndAdjustments,
} from './saveVariationsAndAdjustments';
import { saveWorkItems } from './saveWorkItems';

const getContractTemplate = (contract: Contract) => {
  if (!contract) {
    return '';
  }

  if (!contract.contractTemplate) {
    switch (contract.contractType) {
      case ContractType.SUBCONTRACT: {
        return SupercontractCode.PAID_SUB_2024;
      }
      case ContractType.SUPPLY_CONTRACT: {
        return SupercontractCode.PAID_SUPPLY_2024;
      }
      default: {
        return SupercontractCode.RBN_HEAD_2024;
      }
    }
  }

  switch (contract.contractTemplate) {
    case ContractTemplate.RBN: {
      return SupercontractCode.RBN_HEAD_2024;
    }
    case ContractTemplate.RBN_HIBC: {
      return SupercontractCode.RBN_HIBC_HEAD_2024;
    }
    case ContractTemplate.SUBCONTRACT: {
      return SupercontractCode.PAID_SUB_2024;
    }
    case ContractTemplate.SUPPLY_CONTRACT: {
      return SupercontractCode.PAID_SUPPLY_2024;
    }
  }

  return contract.templateCode ?? '';
};

export const saveContract = (contract: Contract) => {
  if (!contract) return;

  const { suburb, state } = contract.address;
  const contractName = contract?.name ?? '';
  const formattedAddress = formatAddress(contract.address, true);

  contractManager.id = contract.id;
  contractManager.contractName = contractName;
  contractManager.fullContractName = contractName
    ? [contractName, formattedAddress].join(' · ')
    : formattedAddress;

  contractManager.contractAddress = contract.address;
  contractManager.formattedAddress = formattedAddress;
  contractManager.suburbAndState = [suburb, state].filter(Boolean).join(' ');
  contractManager.timezone = contract.timezone ?? '';
  contractManager.theOtherPartyName = contract.theOtherPartyName?.trim() ?? '';
  contractManager.descriptionOfWorks = contract.descriptionOfWorks ?? '';
  contractManager.contractType = contract.contractType;
  contractManager.templateCode = getContractTemplate(contract);
  contractManager.contractTemplate = contract.contractTemplate ?? '';
  contractManager.contractState = contract.contractState;
  contractManager.hasAllSigned = contract.hasAllSigned;
  contractManager.masterContractUrl = contract.masterContractUrl ?? '';
  contractManager.originalContractUrl = contract.originalContractUrl ?? '';
  contractManager.uploadFolderId = contract.uploadFolderId ?? '';

  contractManager.builderMargin = contract.builderMargin ?? 0;
  contractManager.interestRateForLatePayments =
    contract.interestRateForLatePayments ?? ('' as unknown as number);
  contractManager.specialConditions = contract.specialConditions ?? '';
  contractManager.liquidatedDamages = contract.liquidatedDamages ?? null;
  contractManager.defectsLiabilityPeriod =
    (typeof contract.defectsLiabilityPeriod === 'number'
      ? contract.defectsLiabilityPeriod
      : contract.defectsLiabilityPeriod?.liabilityPeriod) ?? 0;
  contractManager.delayAllowances = contract.delayAllowances ?? [];
  contractManager.practicalCompletions = contract.practicalCompletions ?? [];
  contractManager.contractFundings = contract.contractFundings ?? [];
  contractManager.timelines = contract.timelines ?? [];
  contractManager.requirements = contract?.requirements ?? [];
  contractManager.paymentRef = contract.paymentRef ?? '';
  contractManager.paymentTerms = contract.paymentTerms ?? {
    days: 14,
    type: PaymentTermsType.DEFAULT,
  };

  contractManager.currentContract = contract;
  contractManager.additionalData = contract.additionalData?.data ?? {};
  contractManager.snapshot = contract.isDraftContract ? (contract._snapshot ?? null) : null;

  saveParticipantParties(contract.participantParties ?? []);
  saveRolesAndResponsibilities(contract.roles);
  saveGstSettings(contract);
  saveContractPrice(contract.price);
  saveCostOfWorks(contract.costOfWorks);
  saveContractBreakdown(contract);
  saveTimeline(contract);
  saveAttachments(contract.attachments, contract.bankGuarantees);
  saveStatuses(contract);
  saveRequiringActions(contract.id);

  if (
    contract.contractType === ContractType.HEAD_CONTRACT &&
    contract.contractTemplate.startsWith('RBN') &&
    !contract.isDraftContract
  ) {
    saveLoanAndLandOwner(contract as HeadContract | null);
  }
  const { requiringActions } = projectManager;
  const { paymentSchedule, isDraftContract } = contract;
  const isPeriodic = Boolean(paymentSchedule);

  contractManager.statuses.isPeriodic = isPeriodic;
  contractManager.paymentStructure = isPeriodic ? ClaimMethod.PERIODIC : ClaimMethod.STAGED;
  contractManager.paymentSchedule = paymentSchedule ?? null;
  // contractManager.payments = [];

  if (isPeriodic) {
    savePeriodicVariations(contract?.variationWorkItems, contract.participantParties);
    saveWorkItems(contract, requiringActions);
    saveRetentions(contract);
    if (isDraftContract) {
      savePeriodicClaims(contract.progressPayments);
      savePeriodicPermissions();
      saveDefaultActions();
    }
  } else {
    saveInsurances(contract);
    saveVariationsAndAdjustments(contract, requiringActions);
    saveProgressPayments(contract?.progressPayments);
    savePermissions();
    saveDefaultActions();
  }
};
