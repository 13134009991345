import { EvidencePreview } from '@paid-ui/blocks/evidence-preview';
import { GlobalAlertDialog } from '@paid-ui/blocks/global-alert';
import { GlobalConfirmDialog } from '@paid-ui/blocks/global-confirm';
import { GlobalDialog } from '@paid-ui/blocks/global-dialog';
import { GlobalFlyout } from '@paid-ui/blocks/global-flyout';
import { Logo } from '@paid-ui/components/logo';
import { UnstyledButton } from '@paid-ui/components/unstyled-button';
import { AccountIcon } from '@paid-ui/icons/account';
import { ClaimsIcon } from '@paid-ui/icons/claims';
import { Dashboard1Icon } from '@paid-ui/icons/dashboard-1';
import { appConfigManager, getMaintenance } from '@paid-ui/models/app-config';
import { featureManager } from '@paid-ui/models/feature';
import { userManager } from '@paid-ui/models/user';
import { userGroupManager } from '@paid-ui/models/user-group';
import { useRetentionAccountSetup } from '@paid-ui/queries/retention-account';
import { useTransactionAccountSetup } from '@paid-ui/queries/transaction-account';
import { Banner, Flex, styled } from '@paid-ui/ui';
import { useSnapshot } from 'valtio/react';

import { HamburgerMenu } from './hamburger-menu';
import { HelpCenter, Navbar, NavbarLink, NotificationCenter } from './nav-bar';
import { UserCenter } from './user-center/UserCenter';

export const BannerContentContainer = styled(Flex, {
  'color': 'white',
  'alignItems': 'start',
  'flexDirection': 'column',
  'gap': '8px',
  '@md': {
    gap: '18px',
    alignItems: 'center',
    flexDirection: 'unset',
  },
});

type Props = {
  fullHeightNav?: boolean;
  children: React.ReactNode;
  className?: string;
};

const iconClassName =
  'h-[18px] w-[18px] text-white/70 group-hover:text-white group-focus:text-white';

const containerClassName =
  'relative flex cursor-pointer appearance-none flex-col items-center justify-center gap-2 whitespace-nowrap text-white/70 no-underline transition-colors duration-150 ease-linear hover:text-white focus:text-white';

export const NewBasicLayout: React.FC<Props> = ({ fullHeightNav, children, className }) => {
  const { announcements } = useSnapshot(appConfigManager);
  const { enableOnPlatformRetention } = useSnapshot(featureManager);
  const {
    permission: { canManagePayment },
  } = useSnapshot(userGroupManager);
  const { isOwner, isAccountReady, disableContractActions } = useSnapshot(userManager);
  const maintenance = getMaintenance(announcements);
  const enableAccount = isAccountReady;

  useTransactionAccountSetup(enableAccount);
  useRetentionAccountSetup(enableAccount && enableOnPlatformRetention);

  return (
    <div className="grid sm:grid-cols-[80px_1fr]">
      <Navbar fullHeight={fullHeightNav}>
        <div className="grid items-center gap-x-6">
          <nav className="hidden items-center gap-y-6 sm:grid">
            <UnstyledButton as="a" href="/" className="m-auto mt-5 w-[30px] shrink-0">
              <Logo width={30} symbolOnly />
            </UnstyledButton>
            <div className={containerClassName}>
              <NotificationCenter showText side="right" />
            </div>
            <NavbarLink href="/" className="group">
              <Dashboard1Icon className={iconClassName} />
              <span className="text-xs">Dashboard</span>
            </NavbarLink>
            <NavbarLink href="/claims" className="group" hidden={isOwner || !canManagePayment}>
              <ClaimsIcon className={iconClassName} />
              <span className="text-xs">Claims</span>
            </NavbarLink>
            <NavbarLink href="/account" hidden={disableContractActions || !canManagePayment}>
              <AccountIcon className={iconClassName} />
              <span className="text-xs">Account</span>
            </NavbarLink>
            <HelpCenter />
          </nav>
        </div>
        <div className="fixed bottom-5 shrink-0 items-center gap-x-6">
          <UserCenter />
        </div>
      </Navbar>
      <Navbar side="top" fullWidth>
        <div className="flex items-center gap-x-6">
          <UnstyledButton as="a" href="/" className="shrink-0">
            <Logo width={30} symbolOnly />
          </UnstyledButton>
          <HamburgerMenu />
        </div>
        <div className="flex shrink-0 items-center gap-x-6">
          <HelpCenter />
          <NotificationCenter />
        </div>
      </Navbar>
      <Banner
        hidden={!maintenance}
        text={
          <BannerContentContainer>
            <span>
              Scheduled maintenance planned: {maintenance?.startDate} - {maintenance?.endDate} AEST
            </span>
            <span style={{ fontSize: '14px' }}>
              Paid platform including access to transaction account will be unavailable during this
              period
            </span>
          </BannerContentContainer>
        }
      />

      <GlobalDialog />
      <GlobalFlyout />
      <GlobalAlertDialog />
      <GlobalConfirmDialog />
      <EvidencePreview />
      {children}
    </div>
  );
};
